import Details from '../classes/Details';

(() => {
    const $siteFooterNavDropdowns = document.querySelectorAll('.site-footer__nav-dropdown');
    const $newsletter = document.querySelector('.site-footer__newsletter');
    const $newsletterForm = document.querySelector('.site-footer__newsletter-form');
    const $ctaWrap = document.querySelector('.site-footer__cta-wrap');
    const $ctaContent = document.querySelector('.site-footer__cta--fixed .site-footer__cta-content');

    const initSiteFooterNavDropdowns = () => {
        if (!$siteFooterNavDropdowns) return;

        $siteFooterNavDropdowns.forEach($siteFooterNavDropdown => {
            new Details($siteFooterNavDropdown);
        });
    };

    const initNewsletterForm = () => {
        if (!$newsletter || !$newsletterForm) return;

        $newsletterForm.addEventListener('submit', e => {
            e.preventDefault();

            $newsletter.classList.add('site-footer__newsletter--thank-you');
        });
    };

    const initCTAParallax = () => {
        if (!$ctaWrap || !$ctaContent) return;

        const movement = window.innerWidth / 20;

        let scrollFraction = (-1 * ($ctaWrap.getBoundingClientRect().top - window.innerHeight)) / $ctaContent.clientHeight;

        if (scrollFraction > 1) scrollFraction = 1;
        if (scrollFraction < 0) scrollFraction = 0;

        $ctaContent.style.transform = `translateY(${- movement * (1 - scrollFraction)}px)`;
        $ctaContent.style.opacity = scrollFraction;
    };

    window.addEventListener('scroll', initCTAParallax);
    window.addEventListener('resize', initCTAParallax);

    initSiteFooterNavDropdowns();
    initNewsletterForm();
    initCTAParallax();
})();
