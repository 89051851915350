(() => {
    const $siteHeader = document.querySelector('.site-header');

    const initSiteNavDropdowns = () => {
        const $siteMenuModalSubMenuWraps = document.querySelectorAll('.site-header__inner .site-menu .menu-item-has-children');

        if ($siteMenuModalSubMenuWraps.length) {
            $siteMenuModalSubMenuWraps.forEach($subMenuWrap => {
                const $subMenu = $subMenuWrap.querySelector('.sub-menu-dropdown');
                const $subMenuInner = $subMenu.querySelector('.sub-menu-dropdown__inner');

                const hideSubMenu = () => {
                    $subMenu.style.height = `${$subMenuInner.offsetHeight}px`;
                    $subMenuWrap.classList.remove('menu-item--active');
                    $activeChildSubMenus = $subMenuInner.querySelectorAll('.menu-item--active');

                    if ($siteHeader) {
                        $siteHeader.classList.remove('site-header--sub-menu-active');
                    }

                    setTimeout(() => {
                        $subMenu.style.height = '';
                    }, 10);

                    setTimeout(() => {
                        if ($activeChildSubMenus && !$subMenuWrap.classList.contains('menu-item--active')) {
                            $activeChildSubMenus.forEach($activeChildSubMenu => {
                                $activeChildSubMenu.classList.remove('menu-item--active');

                                if ($siteHeader) {
                                    $siteHeader.classList.remove('site-header--sub-menu-active');
                                }
                            });
                        }
                    }, 300);
                };

                window.addEventListener('click', e => {
                    if (!$subMenuWrap.classList.contains('menu-item--active') || $subMenuWrap.contains(e.target)) return;

                    hideSubMenu();
                });
            });
        }
    };

    initSiteNavDropdowns();
})();
