const detailsOpenEvent = new CustomEvent('detailsopen', {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false
});

const detailsCloseEvent = new CustomEvent('detailsclose', {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false
});

const noMotionPreference = window.matchMedia('(prefers-reduced-motion: no-preference)');

export default class Details {
    constructor($el) {
        this.el = $el;

        this.summary = $el.querySelector('summary');
        this.animating = false;

        this.summaryChildren = this.summary.querySelectorAll('a, button');

        if (this.summaryChildren) {
            this.summaryChildren.forEach($el => {
                $el.addEventListener('click', e => {
                    e.stopPropagation();
                });
            });
        }

        this.summary.addEventListener('click', (e) => this.onClick(e));

        this.el.classList.add('details--initiated');
    };

    onClick(e) {
        e.preventDefault();

        if (this.animating) return;

        if (this.el.open) {
            this.close();
        } else {
            this.open();
        }
    };

    open() {
        this.el.dispatchEvent(detailsOpenEvent);

        this.animating = true;
        this.el.style.height = `${this.summary.clientHeight}px`;
        this.el.open = true;

        window.requestAnimationFrame(() => {
            this.el.classList.add('details--active');
            this.el.style.height = `${this.el.scrollHeight}px`;

            setTimeout(() => {
                this.el.style.height = '';
                this.animating = false;
            }, noMotionPreference.matches ? 500 : 1);
        });
    };

    close() {
        this.el.dispatchEvent(detailsCloseEvent);

        this.animating = true;
        this.el.style.height = `${this.el.offsetHeight}px`;

        window.requestAnimationFrame(() => {
            this.el.style.height = `${this.summary.offsetHeight}px`;
            this.el.classList.remove('details--active');

            setTimeout(() => {
                this.el.style.height = '';
                this.el.open = false;
                this.animating = false;
            }, noMotionPreference.matches ? 500 : 1);
        });
    };
}