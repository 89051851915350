import Details from '../classes/Details';

(() => {
    const $reviewFormExpandBtnWrap = document.querySelector('.review-form__expand-btn-wrap');
    const $reviewFormExpandBtn = document.querySelector('.review-form__expand-btn');
    const $reviewFormContent = document.querySelector('.review-form__content');

    const initReviewFormExpandable = () => {
        if (!$reviewFormExpandBtnWrap || !$reviewFormExpandBtn || !$reviewFormContent) return;

        $reviewFormExpandBtn.addEventListener('click', () => {
            $reviewFormExpandBtnWrap.style.height = `${$reviewFormExpandBtnWrap.scrollHeight}px`;

            $reviewFormContent.style.height = `${$reviewFormContent.scrollHeight}px`;

            $reviewFormExpandBtnWrap.classList.add('review-form__expand-btn-wrap--hide');
            $reviewFormContent.classList.add('review-form__content--show');

            window.requestAnimationFrame(() => {
                $reviewFormExpandBtnWrap.style.height = '0px';

                setTimeout(() => {
                    $reviewFormContent.style.height = '';
                    $reviewFormExpandBtnWrap.style.height = '';
                }, 300);
            });
        });
    };

    initReviewFormExpandable();
})();
